import React from "react";

// State
import {
  GlobalStore,
  setActiveLayer
} from "@data";
import { useSnapshot } from "valtio";


// LAYER CONTROLS
export const LayerControls = () => {
  const { citiesData, activeCity, switchableLayers, isAdmin } = useSnapshot(GlobalStore);

  const layers = switchableLayers.map((layer, i) => {
    return(
      <Layer
        key={i}
        id={i}
        miniMap={layer.miniMap}
        label={layer.name}
      />
    );
  });

  if ((activeCity === null || !citiesData[activeCity].available) && !isAdmin) return;
  return (
    <div className="layer-controls">
      {layers}
    </div>
  );
};

const Layer = (props) => {
  const { activeSwitchableLayer } = useSnapshot(GlobalStore);

  return (
    <button
      className={"layer " + (activeSwitchableLayer === props.id ? "active" : "")}
      onClick={() => setActiveLayer(props.id)}
      aria-label="Váltás az alaprétegek között"
    >
      <div className="layer__minimap">
        <img
          src={props.miniMap}
          alt="mini-map"
        />
        <span className="layer__minimap__label">
          {props.label}
        </span>
      </div>
    </button>
  );
};