import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import { useSearchParams, Route, Routes } from "react-router-dom";
import { useSnapshot } from "valtio";

// State
import { GlobalStore, setIsAdmin } from "@data";

// Components
import { Main, Partners } from "@pages";
import { urls, cookieGetAdminHash, cookieGetSubscribed, cookieSetAdminHash, cookieSetSubscribed, cookieRemoveSubscribed, getCitiesData } from "@api";

// View Height calculation helper
const calculateVh = () => {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
};


// APP
const App = () => {
  const { selectedBuilding, cookies } = useSnapshot(GlobalStore);
  const [ urlParam ] = useSearchParams();

  // Get cities from Contentful
  useEffect(() => {
    getCitiesData();
  }, []);

  // Fix mobile vh issue
  useEffect(() => {
    calculateVh();
    window.addEventListener('resize', calculateVh);
    window.addEventListener('orientationchange', calculateVh);
  }, []);

  // Fix mobile overflow problem
  useEffect(() => {
    if (selectedBuilding === null) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }
  }, [selectedBuilding]);

  // Init cookie handling
  useEffect(() => {
    if (!cookies) return;
    
    // Initialize GA if cookies are accepted
    ReactGA.initialize("G-82RXH7PHPV");

    // Get initial subscribed cities and update GlobalStore accordingly
    const subscribeData = cookieGetSubscribed();
    if (subscribeData) {

      fetch(`${urls.mcMemberInfo}?email=${subscribeData.email}`)
      .then(res => res.json())
      .then(res => {
        if(res.tags.length > 0) {
          const subscribedCities = res.tags.map(item => item.name);
          cookieSetSubscribed(subscribeData.email, subscribedCities);
        } else {
          cookieRemoveSubscribed();
        }
      })
      .catch(err => console.log(err));
    }

    // Check Admin Hash from cookie
    if (cookieGetAdminHash() === process.env.REACT_APP_ADMIN_HASH) {
      setIsAdmin(true);
    }
  }, [cookies]);

  // Check UrlParam for adminHash
  useEffect(() => {
    const initHash = urlParam.get("hash");
    if (initHash === process.env.REACT_APP_ADMIN_HASH) {
      cookieSetAdminHash(initHash);
    }
  }, [urlParam]);

  return (
    <div className="App">
      <Routes>
        <Route path="/">
          <Route index element={<Main/>} />
          <Route path="partnereink" element={<Partners/>} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;