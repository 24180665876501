import React from "react";
import { useSnapshot } from "valtio";

// State
import { GlobalStore } from "@data";

// Components
import { WelcomeModal, Header, MapView, BuildingPanel } from "@partials";
import { CookiePanel, Onboarding } from "@molecules";


export const Main = () => {
	const { citiesData, activeCity, selectedBuilding, isAdmin } = useSnapshot(GlobalStore);

	return (
		<>
      <WelcomeModal/>
      <main className={selectedBuilding === null ? "" : "collapsed"}>
        <Header/>
        <MapView/>
        <CookiePanel/>
        {activeCity !== null && citiesData[activeCity].available && !isAdmin && (
          <Onboarding/>
        )}
      </main>
      <BuildingPanel/>
    </>
	);
};