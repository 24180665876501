import React from "react";

// Assets
import logoMNNSZ from "@images/logo-mnnsz.png";
import logoSF from "@images/logo-sf.png";
import logoEUS from "@images/logo-eus.png";
import logoSHSH from "@images/logo-shsh.png";
import logoEH from "@images/logo-eh.png";
import logoSM from "@images/logo-sm.png";
import { Header } from "@partials";


const partners = [
	{
		title: "Magyar Napelem Napkollektor Szövetség",
		logo: logoMNNSZ,
		link: "https://www.mnnsz.hu"
	},
	{
		title: "Solar-Fusion Kft.",
		logo: logoSF,
		link: "https://solarfusion.hu"
	},
	{
		title: "EU-SOLAR Nyrt.",
		logo: logoEUS,
		link: "https://www.eu-solar.hu"
	},
	{
		title: "SH Solar Hungary Kft.",
		logo: logoSHSH,
		link: "https://shsolar.hu"
	},
	{
		title: "Envirosense Hungary Kft.",
		logo: logoEH,
		link: "https://envirosense.hu"
	},
	{
		title: "NOVASALES Kft.",
		logo: logoSM,
		link: "https://sunme.hu"
	},
];


// WELCOME MODAL
export const Partners = () => {

	return (
		<main>
			<Header link="/"/>
			<div className="page">

				<div className="page__content">
					<div className="page__content__head">
						<h3>Együttműködő partnereink</h3>
					</div>
					
					<div className="page__content__body">
						<ul className="page__content__body__copy__partners">
							{partners.map((partner, i) => {
								return (
									<li key={i}>
										<a
											href={partner.link}
											target="_blank"
											rel="noreferrer"
										>
											<div
												className="cover"
												style={{ backgroundImage: `url(${partner.logo})`}}
											></div>
											<span>{partner.title}</span>
										</a>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				</div>
		</main>
	);
};