import React, { createContext, useRef, useState, useEffect, useCallback } from "react";
import { useSnapshot } from "valtio";
import { useSearchParams } from "react-router-dom";
import throttle from 'lodash.throttle';

// OL Main
import Map from "ol/Map";
import View from "ol/View";
// OL Projection
import { fromLonLat, transformExtent } from "ol/proj";
// OL Controls
import { defaults as controlDefaults} from "ol/control/defaults";
// OL Interactions
import { defaults as interactionDefaults} from 'ol/interaction/defaults';
import { createEmpty, extend } from "ol/extent";
// OL Extent specific
import Feature from 'ol/Feature';
import Point from "ol/geom/Point";
import { MultiPolygon } from 'ol/geom';
import {fromExtent} from 'ol/geom/Polygon';
// OL - Vector cliping imports
import {getVectorContext} from 'ol/render';
import {Fill, Style} from 'ol/style';

import {
  GlobalStore,
  setActiveCity,
  setBuilding,
  unsetBuilding,
  setOnboardingStage,
  MapConfig,
  setMapLoading
} from "@data";
import {
  citiesCluster,
  backroundLayer,
  imageLayer,
  buildingFootprintLayer,
  highlightLayer,
  selectionLayer,
  cityFootprintLayer,
  ZoomControls,
  LayerControls,
  MapFooter,
  ComingSoonPanel,
} from "@partials";
import ZoomInCursor from "@images/Cursor";


// MapContext context
const MapContext = createContext();


// MAPVIEW
export const MapView = () => {
  const {
    citiesData,
    activeCity,
    activeSwitchableLayer,
    switchableLayers,
    selectedBuilding,
    searchBounds,
    onboardingStage,
    isAdmin
  } = useSnapshot(GlobalStore);
  
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [cities, setCities] = useState(null);
  const [hover, setHover] = useState(null);
  const [citiesClusterSource, setCitiesClusterSource] = useState(null);
  const [cityFootprintLayerSource, setCityFootprintLayerSource] = useState(null);
  const [imageLayerSource, setImageLayerSource] = useState(null);
  const [buildingFootprintLayerSource, setBuildingFootprintLayerSource] = useState(null);
  const [highlightSource, setHighlightSource] = useState(null);
  const [selectionSource, setSelectionSource] = useState(null);

  // url param
  const [urlParamInit, setUrlParamInit] = useState(false);
  const [urlParam, setUrlParam] = useSearchParams();

  // MOUNT MAP
  useEffect(() => {
    let options = {
      view: new View({
        projection: MapConfig.baseViewProjection,
        center: MapConfig.baseViewWMCenter,
        zoom: MapConfig.initZoom,
        minZoom: MapConfig.initZoom,
        maxZoom: MapConfig.maxZoom,
        extent: MapConfig.maxViewExtent(),
      }),
      layers: [
        backroundLayer,
        cityFootprintLayer,
        imageLayer,
        buildingFootprintLayer,
        highlightLayer,
        selectionLayer,
        citiesCluster,
      ],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
        attribution: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false
      })
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    setCitiesClusterSource(citiesCluster.getSource());
    setCityFootprintLayerSource(cityFootprintLayer.getSource());
    setImageLayerSource(imageLayer.getSource());
    setBuildingFootprintLayerSource(buildingFootprintLayer.getSource());
    setHighlightSource(highlightLayer.getSource());
    setSelectionSource(selectionLayer.getSource());
    
    return () => mapObject.setTarget(undefined);
  },[]);

  // --- URL SEARCH PARAM ---
  // Check Initial UrlParam
  useEffect(() => {
    if (map && citiesData.length && !urlParamInit) {
      const initSlug = urlParam.get("c");

      if (initSlug) {
        // Find Index inside citiesData
        let initIndex = citiesData.findIndex(
          item => item.slug === initSlug
        );

        // Go ahead if Index is valid
        if (initIndex >= 0) {
          // Set as the active city
          setActiveCity(initIndex);
          // Transform extent data
          const transExtent = transformExtent(
            citiesData[initIndex].extent,
            "EPSG:4326",
            "EPSG:3857"
          );
          // Fit view to extent
          const getView = map.getView();
          getView.fit(
            transExtent,
            { duration: 400 },
          );
        }
      }
      // Set init state
      setUrlParamInit(true);
    }
  }, [map, citiesData, urlParam, urlParamInit]);

  // Handle UrlParam Change when activeCity changes
  useEffect(() => {
    if (urlParamInit) {
      if (activeCity !== null) {
        setUrlParam({ c: citiesData[activeCity].slug });
      } else {
        urlParam.delete("c");
        setUrlParam(urlParam);
      }
    }
  }, [citiesData, activeCity, urlParam, urlParamInit, setUrlParam]);

  // --- CITIES ---
  // Set city pins on base map
  useEffect(() => {
    if (citiesData && citiesData.length && cities === null) {
      const citiesCount = citiesData.length;
      const citiesArray = new Array(citiesCount);

      for (let i = 0; i < citiesCount; ++i) {
        citiesArray[i] = new Feature(new Point(fromLonLat(citiesData[i].center)));

        // Add parameters to pin
        citiesArray[i].id = i;
        citiesArray[i].name = citiesData[i].name;
        citiesArray[i].available = isAdmin ? citiesData[i].solarUrl !== null : citiesData[i].available;
      }
      setCities(citiesArray);
    }
  }, [citiesData, cities, isAdmin]);

  // Add cities to cluster source
  useEffect(() => {
    if (citiesClusterSource) {
      if (cities && citiesClusterSource.getSource().isEmpty()) {
        citiesClusterSource.getSource().addFeatures(cities);
      }
    }
  }, [citiesClusterSource, cities, isAdmin]);

  // Handle active city changes
  useEffect(() => {
    if (!map) return;

    const handleChangeToCity = (city) => {
      // Cluster pin
      citiesCluster.setVisible(false);

      // City footprint polygon
      const coords = city.polygon.map(polygon => {
        return polygon.map(coord => fromLonLat(coord));
      });
      const feature = new Feature(new MultiPolygon([coords]));
      feature.available = isAdmin ? true : city.available;
      cityFootprintLayerSource.clear();
      cityFootprintLayerSource.addFeature(feature);

      // Layers
      if(citiesData[activeCity].available || (isAdmin && citiesData[activeCity].solarUrl !== null)) {

        // Image layer to visible
        imageLayer.setVisible(true);

        // Building footprint layer url change
        buildingFootprintLayer.getSource().setUrl(
          'https://envimap.hu/geoserver/gwc/service/tms/1.0.0/'
          + city.buildingFootprintLayer
          + '@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'
        );

        // Building footprint layer
        buildingFootprintLayer.setVisible(true);
      } else {
        // Image layer
        imageLayer.setVisible(false);
        buildingFootprintLayer.setVisible(false);
      }
    };

    const handleChangeToCountry = () => {
      citiesCluster.setVisible(true);
      imageLayer.setVisible(false);
      cityFootprintLayerSource.clear();
      unsetBuilding();
    };

    activeCity !== null
      ? handleChangeToCity(citiesData[activeCity])
      : handleChangeToCountry();
    
  }, [activeCity, map, cityFootprintLayerSource, imageLayerSource, isAdmin]);

  // --- IMAGELAYER ---
  // Handle active switchable image layer
  useEffect(() => {
    if (!map || activeCity === null) return;

    if(citiesData[activeCity].available || isAdmin) {
      // Set image layer url
      const imageTileSource = citiesData[activeCity][switchableLayers[activeSwitchableLayer].url];

      if (imageTileSource === null) {
        imageLayerSource.setUrl('https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}');
      } else {
        imageLayerSource.setUrl(
          'https://tileserver{1-8}.envimap.hu/'
          + imageTileSource
          + '/{z}/{x}/{y}'
        );
      }
    }
  }, [map, activeCity, imageLayerSource, activeSwitchableLayer, switchableLayers, isAdmin]);

  // --- VIEW ---
  // Handle view checking
  const checkInView = useCallback((view, size) => {
    const viewExtent = view.calculateExtent(size);
    const pinFeaturesInView = citiesClusterSource.getSource().getFeaturesInExtent(viewExtent);
    const footprintFeaturesInView = cityFootprintLayerSource.getFeaturesInExtent(viewExtent);
    
    setActiveCity(
      pinFeaturesInView.length === 1 && view.getZoom() > 10
        ? pinFeaturesInView[0].id
        : footprintFeaturesInView.length >= 1 && pinFeaturesInView.length <= 1 && view.getZoom() > 10
          ? activeCity
          : null
    );
  }, [activeCity, citiesClusterSource, cityFootprintLayerSource]);

  // --- SEARCH ---
  // Navigate to the searched area bounds and show bounds on map
  useEffect(() => {
    if (searchBounds.length > 0) {

      if (searchBounds.length > 2) {
        const transSearchBounds = transformExtent(searchBounds,"EPSG:4326","EPSG:3857");

        // Fit view
        let getView = map.getView();
        getView.fit(
          transSearchBounds,
          { duration: 600 }
        );
      } else {
        const transSearchBounds = fromLonLat([searchBounds[1], searchBounds[0]], MapConfig.baseViewProjection);

        // Fit view
        let getView = map.getView();
        getView.animate({
          center: transSearchBounds,
          zoom: 19,
          duration: 600
        });
      }
    }
  }, [searchBounds, map]);

  // Clip Google satalite layer with available area
  useEffect(() => {
    if (!map) return;
    
    // Set Extent
    cityFootprintLayer.getSource().on('addfeature', () => {
      imageLayer.setExtent(cityFootprintLayer.getSource().getExtent());
    });

    // Style cliping
    const style = new Style({
      fill: new Fill({
        color: [0, 0, 0, 1],
      }),
    });
    
    // Clip layer
    imageLayer.on('postrender', (e) => {
      const vectorContext = getVectorContext(e);
      e.context.globalCompositeOperation = 'destination-in';
      cityFootprintLayer.getSource().forEachFeature(function (feature) {
        vectorContext.drawFeature(feature, style);
      });
      e.context.globalCompositeOperation = 'source-over';
    });

    // Building footprint load indicator
    buildingFootprintLayerSource.on('tileloadstart', () => {
      setMapLoading(true);
    });
    buildingFootprintLayerSource.on(['tileloadend', 'tileloaderror'], () => {
      setMapLoading(false);
    });
  }, [map]);

  // --- HELPERS ---
  // Handle cursor type change
  const swapCursor = useCallback((type) => {
    document.body.style.cursor = type;
  },[]);

  // Helper for expanding highlight or selection extents
  const expandExtent = (baseExtent, padding) => {
    return [baseExtent[0]-padding, baseExtent[1]-padding, baseExtent[2]+padding, baseExtent[3]+padding];
  };

  // Helper for creating new feature for highlight or selection extent
  const createExtent = useCallback((feature) => {
    const baseExtent = feature.getGeometry().getExtent();
    const polygon = fromExtent(expandExtent(baseExtent, 1.5));
    return new Feature(polygon);
  },[]);

  // Helper for "merging" tiled features
  const findFeatureExtent = useCallback((feature, padding) => {
    // Get variables from feature
    let featureExtent = feature.getExtent();
    const featureID = feature.getId();

    // Expand hovered feature extent for searching
    const scopeExtent = expandExtent(featureExtent, padding);

    // Get array of features inside search extent
    const featuresInExtent = buildingFootprintLayer.getSource().getFeaturesInExtent(scopeExtent);

    // Filter array with the hovered feature ID
    const filteredFeaturesInExtent = featuresInExtent.filter(f => f.id_ === featureID);

    // Calculate combined extent
    filteredFeaturesInExtent.forEach(filteredFeature => {
      // Get filtered feature extent
      const filteredExtent = filteredFeature.getExtent();
      // Check filtered feature is out of prev extent in some direction
      for (let i = 0; i < 4; i++) {
        if (i <= 1) {
          featureExtent[i] = filteredExtent[i] < featureExtent[i] ? filteredExtent[i] : featureExtent[i];
        } else {
          featureExtent[i] = filteredExtent[i] > featureExtent[i] ? filteredExtent[i] : featureExtent[i];
        }
      }
    });
    return featureExtent;
  },[]);

  // --- INTERACTIONS ---
  // Handle Map events
  useEffect(() => {
    if (!map || !citiesData.length) return;

    const view = map.getView();

    // Helper for getting buildingFootprintLayer feature under mouse pixel
    const getFeature = (pixel, scopedLayer) => map.forEachFeatureAtPixel(pixel, feature => feature, {
      layerFilter: layer => layer === scopedLayer
    });


    // Map MOVEMENT handling
    map.on('moveend', () => {
      const size = map.getSize();
      const zoom = view.getZoom();

      checkInView(view, size);

      if(zoom < 17) {
        setHover(null);
      } else if (onboardingStage === 0) {
        setOnboardingStage(1);
      }
    });


    // Map HOVER event handling
    map.on('pointermove', throttle((e) => {
      if (e.dragging) return;

      const zoom = view.getZoom();
      const cityPinFeature = getFeature(e.pixel, citiesCluster);
      const cityFootprintFeature = getFeature(e.pixel, cityFootprintLayer);
      const feature = getFeature(e.pixel, buildingFootprintLayer);

      // Cursor handling
      if(cityPinFeature) {
        swapCursor("pointer");
      } else if (cityFootprintFeature?.available && zoom <= 17) {
        swapCursor(ZoomInCursor);
      } else if (feature) {
        findFeatureExtent(feature, 100);
        setHover(feature);
      } else {
        swapCursor("default");
        setHover(null);
      }
    }, 200));
    
    
    // Map CLICK event handling
    map.on('click', (e) => {

      citiesCluster.getFeatures(e.pixel).then((features) => {

        // Select city if user clicks on a city pin
        if (features.length > 0) {
          const clusterMembers = features[0].get("features");

          if (clusterMembers.length > 1) {
            // Calculate the extent of the cluster members.
            const extent = createEmpty();
            clusterMembers.forEach((feature) =>
              extend(extent, feature.getGeometry().getExtent())
            );
            // Fit view to extent
            view.fit(extent, {
              duration: 400,
              padding: [250, 250, 250, 250],
            });
          } else {
            // Transform cityData extent
            const transExtent = transformExtent(
              citiesData[clusterMembers[0].id].extent,
              "EPSG:4326",
              "EPSG:3857"
            );
            // Fit view to extent
            view.fit( transExtent, {
                duration: 400,
                padding: [50, 50, 50, 50]
              },
            );
          }
        } else {
          const footprintFeature = getFeature(e.pixel, cityFootprintLayer);

          // Check if the user has clicked inside a city area
          if (footprintFeature) {
            const feature = getFeature(e.pixel, buildingFootprintLayer);

            // Check if the user has clicked on a building
            if (feature) {
              const mergedExtent = findFeatureExtent(feature, 100);
              setBuilding({feature, mergedExtent});

              // Handle onboarding stage change to 2
              if(onboardingStage < 2) {
                setOnboardingStage(2);
              }
            } else {
              // If zoomed out than zoom in
              // to a level where buildingFootprintLayer is visible
              if (view.getZoom() < 17 && footprintFeature.available) {
                view.animate({
                  center: e.coordinate,
                  zoom: 17.1,
                  duration: 200
                });
              }

              // Unset
              unsetBuilding();
            }
          }
        }
      });
    });
  }, [map, citiesData, onboardingStage, checkInView, findFeatureExtent]);

  // Hover Feature handling
  useEffect(() => {
    if (!highlightSource) return;

    if (hover !== null) {
      let feature = createExtent(hover);
      highlightSource.clear();
      highlightSource.addFeature(feature);
      swapCursor("pointer");
    } else {
      highlightSource.clear();
    }
  }, [hover, highlightSource, createExtent, swapCursor]);

  // Selected Feature handling
  useEffect(() => {

    // Resize map canvas to mapview container
    if (map) map.updateSize();

    // Handle selection on map view
    if (!selectionSource) return;

    if (selectedBuilding !== null) {
      const getView = map.getView();

      // Fit view to building polygon
      getView.fit(
        selectedBuilding.mergedExtent,
        { 
          padding: [80, 80, 80, 80],
          duration: 400
        }
      );

      // Display selection
      const feature = createExtent(selectedBuilding.feature);
      selectionSource.clear();
      selectionSource.addFeature(feature);
    } else {
      // Clear selection
      selectionSource.clear();
    }
  }, [map, selectedBuilding, selectionSource, createExtent]);


  return (
    <div className="map-view">
      { (activeCity !== null) && ((!isAdmin && !citiesData[activeCity].available) ||
        (isAdmin && citiesData[activeCity].solarUrl === null)) && (
        <ComingSoonPanel />
      )}
      <MapContext.Provider value={{ map }}>
        <div
          ref={mapRef}
          className="map-canvas"
        />
      </MapContext.Provider>
      <ZoomControls map={map}/>
      <LayerControls/>
      <MapFooter/>
    </div>
  );
};