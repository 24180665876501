import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

import { urls, cookieRemoveSubscribed, cookieSetSubscribed } from "@api";
import { GlobalStore } from "@data";
import { Done } from "@icons";
import { ButtonTextIcon, Checkbox, Field } from "@molecules";


// comingsoon MODAL
export const ComingSoonPanel = () => {
  const { citiesData, cookies, subscribed, activeCity, isAdmin } = useSnapshot(GlobalStore);
	const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [status, setStatus] = useState("");

  // Form change handlers
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleConsentChange = () => setConsent(!consent);

  // Handle user subscribe
  const handleSubscribe = async (e) => {
    e.preventDefault();

    if(email && (consent || subscribed.email)) {
      setStatus("sending");

      fetch(`${urls.mcSubscribe}?email=${email}&city=${citiesData[activeCity].name}`)
      .then(res => res.json())
      .then(res => {
        if(res.id) {
          // Set panel status
          setStatus("subscribed");

          // Save subscribtion to cookie and state
          if(res.tags.length > 0 && cookies) {
            const subscribedCities = res.tags.map(item => item.name);
            cookieSetSubscribed(res.email_address, subscribedCities);
          } else {
            cookieRemoveSubscribed();
          }
        } else {
          setStatus("error");
        }
      })
      .catch(err => {
        console.log(err);
        setStatus("error");
      });
    }
  };

  // Handle user unsubscribe
  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    fetch(`${urls.mcUnsubscribe}?email=${subscribed.email}&city=${citiesData[activeCity].name}`)
    .then(res => {
      if (res.ok) {
        // Set panel status
        setStatus("");

        // Save subscribtion to cookie and state
        const updatedCities = subscribed.cities.filter(function(item) {
          return item !== citiesData[activeCity].name;
        });
        if (updatedCities.length > 0) {
          cookieSetSubscribed(subscribed.email, updatedCities);
        } else {
          cookieRemoveSubscribed();
        }
      }
    })
    .catch(err => {
      console.log(err);
      setStatus("error");
    });
  };

  // Handle subscription from Cookie
  useEffect(() => {
    // Autofill email from cookie
    if(subscribed.email && !isAdmin) {
      setEmail(subscribed.email);
    }

    // Change panel status
    if (subscribed.cities && citiesData[activeCity]) {
      const activeCityName = citiesData[activeCity].name;
      const isSubscribed = subscribed.cities.includes(activeCityName);
  
      if (isSubscribed) {
        setStatus("subscribed");
      }
    }
  }, [subscribed, activeCity]);

	return (
		<div className="comingsoon-panel">
			
			<div className="comingsoon-panel__head">
				<h4>{citiesData[activeCity].name}</h4>
				<h3>{citiesData[activeCity].availableNote}</h3>
			</div>

      {status !== "subscribed"
        ? (
          <>
            <p>Iratkozzon fel és emailben értesítjük, amint elérhető lesz szolgáltatásunk a városban!</p>
            <form onSubmit={handleSubscribe}>
              <div className="fields">
                <Field
                  style="dark"
                  type="email"
                  id="email"
                  value={email}
                  placeholder="E-mail cím..."
                  onChange={handleEmailChange}
                  required
                />
                {(!subscribed.email || subscribed.email !== email) && (
                  <Checkbox
                    onChange={handleConsentChange}
                    checked={consent}
                    id="constent"
                    label={<span dangerouslySetInnerHTML={{__html: "Elfogadom az <a href='/Adatkezelesi_Tajekoztato-latest.pdf' target='_blank'>Adatkezelési Tájékoztató</a>-ban leírtakat és hozzájárulok adataim kezeléséhez."}} />}
                    required
                  />
                )}
              </div>
              {status !== "sending"
                ? (
                  <ButtonTextIcon text="Feliratkozás"/>
                ) : (
                  <div className="form-loader">
                    <span>Folyamatban...</span>
                  </div>
                )
              }
              {status === "error" && (
                <div className="comingsoon-panel__error">
                  <span className="span--bold">Sikertelen feliratkozás!</span>
                  <span>Kérjük próbálja meg később újra vagy lépjen velünk kapcsolatba.</span>
                </div>
              )}
            </form>
          </>
        ) : (
          <>
            <div className="comingsoon-panel__success">
              <Done/>
              <h5>Sikeresen feliratkozott</h5>
            </div>
            <p>Jelentkezünk, amint elérhető lesz szolgáltatásunk a városban.</p>
            <div className="comingsoon-panel__success__subinfo">
              <span className="address">
                {subscribed.email}
              </span>
              <button
                className="unsubscribe"
                onClick={handleUnsubscribe}
              >
                <span className="span--bold">Leiratkozás</span>
              </button>
            </div>
          </>
        )
      }
		</div>
	);
};