// Store
import { setCities } from "@data";

// Sending a request with selected position
export const getCitiesData = async () => {
  await fetch(`https://graphql.contentful.com/content/v1/spaces/jikd7rhibzjz/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => response.json())
    .then((response) => {
      setCities(response.data.cityCollection.items);
    })
    .catch((error) => console.log(error));
};

const query = `{
  cityCollection(limit: 500, skip: 0) {
    items {
      name
      slug
      available
      availableNote
      center
      extent
      polygon
      solarUrl
      ortoUrl
      buildingFootprintLayer
    }
  }
}`;